define("ember-key-manager/modifiers/key-macro", ["exports", "ember-modifier", "@ember/service", "@ember/debug"], function (_exports, _emberModifier, _service, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.cmdKey = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const isMac = window.navigator.platform === "MacIntel";
  const cmdKey = isMac ? 'Meta' : 'Control';
  _exports.cmdKey = cmdKey;
  let KeyMacroModifier = (_class = class KeyMacroModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "keyManager", _descriptor, this);
    }

    addMacro() {
      this.macro = this.keyManager.addMacro({
        element: this.element,
        callback: this.callback,
        executionKey: this.executionKey,
        modifierKeys: this.modifierKeys,
        priority: this.priority,
        keyEvent: this.keyEvent,
        isDisabledOnInput: this.isDisabledOnInput
      });
    }

    removeMacro() {
      if (this.macro) {
        this.keyManager.removeMacro(this.macro);
      }
    }

    get callback() {
      (false && !(this.args.positional.length > 1) && (0, _debug.assert)(`A callback function must be supplied as the second parameter of a ${this.name} modifier`, this.args.positional.length > 1));
      return this.args.positional[1];
    }

    get executionKey() {
      (false && !(this.args.positional.length > 0) && (0, _debug.assert)(`executionKey must be supplied as the first parameter of a ${this.name} modifier`, this.args.positional.length > 0));
      return this.args.positional[0];
    }

    get modifierKeys() {
      return this.args.named.modifierKeys || [];
    }

    get priority() {
      return this.args.named.priority || 0;
    }

    get isDisabledOnInput() {
      return this.args.named.disabledOnInput || false;
    }

    didReceiveArguments() {
      this.removeMacro();
      this.addMacro();
    }

    willDestroy() {
      this.removeMacro();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "keyManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = KeyMacroModifier;
});