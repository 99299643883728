define('ember-can/utils/normalize', ['exports', '@ember/string', 'ember-inflector'], function (exports, _string, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (string) {
    let parts = string.split(' ');
    let abilityName = (0, _emberInflector.singularize)(parts.pop());
    let last = parts[parts.length - 1];

    if (stopWords.includes(last)) {
      parts.pop();
    }

    let propertyName = (0, _string.camelize)(parts.join(' '));

    return { propertyName, abilityName };
  };

  const stopWords = ['of', 'in', 'for', 'to', 'from', 'on', 'as'];

  /**
   * Normalize string into an object with extracted propertyName and abilityName
   * eg. for 'create projects in account' -> `{ propertyName: 'createProjects', abilityName: 'account'}`
   * @private
   * @param  {String} string eg. 'create projects in account'
   * @return {Object}        extracted propertyName and abilityName
   */
});