define("ember-key-manager/utils/macro", ["exports", "@ember/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Macro {
    constructor(options) {
      _defineProperty(this, "keyEvent", 'keydown');

      _defineProperty(this, "callback", () => {});

      _defineProperty(this, "element", document.body);

      _defineProperty(this, "executionKey", '');

      _defineProperty(this, "isDisabledOnInput", false);

      _defineProperty(this, "modifierKeys", []);

      _defineProperty(this, "priority", 0);

      _defineProperty(this, "groupName", null);

      _defineProperty(this, "isDisabled", false);

      if (options.modifierKeys !== undefined) {
        this.modifierKeys = (0, _array.A)(options.modifierKeys);
      }

      if (options.element !== undefined) {
        this.element = options.element;
      }

      this.callback = options.callback;
      this.executionKey = options.executionKey;

      if (options.keyEvent) {
        this.keyEvent = options.keyEvent;
      }

      if (options.isDisabledOnInput !== undefined) {
        this.isDisabledOnInput = options.isDisabledOnInput;
      }

      if (options.priority !== undefined) {
        this.priority = options.priority;
      }

      if (options.groupName !== undefined) {
        this.groupName = options.groupName;
      }

      if (options.isDisabled !== undefined) {
        this.isDisabled = options.isDisabled;
      }
    }

  }

  _exports.default = Macro;
});