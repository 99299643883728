define("ember-changeset-validations/utils/validation-errors", ["exports", "@ember/object", "@ember/debug", "@ember/polyfills", "ember-get-config", "ember-changeset-validations/utils/get-messages"], function (_exports, _object, _debug, _polyfills, _emberGetConfig, _getMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildMessage;

  /**
   * For code taken from ember-cp-validations
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */
  function buildMessage(key, result) {
    let returnsRaw = (0, _object.get)(_emberGetConfig.default, 'changeset-validations.rawOutput') || false;
    let messages = (0, _getMessages.default)();
    let description = messages.getDescriptionFor(key);

    if (result.message) {
      return result.message;
    }

    let {
      type,
      value,
      context = {}
    } = result;

    if (context.message) {
      let message = context.message;

      if (typeof message === 'function') {
        let builtMessage = message(key, type, value, context);
        (false && !(typeof builtMessage === 'string') && (0, _debug.assert)('Custom message function must return a string', typeof builtMessage === 'string'));
        return builtMessage;
      }

      return messages.formatMessage(message, (0, _polyfills.assign)({
        description
      }, context));
    }

    let message = (0, _object.get)(messages, type);

    if (returnsRaw) {
      context = (0, _polyfills.assign)({}, context, {
        description
      });
      return {
        value,
        type,
        message,
        context
      };
    } else {
      return messages.formatMessage(message, (0, _polyfills.assign)({
        description
      }, context));
    }
  }
});