define("@sentry/ember/index", ["exports", "@sentry/browser", "ember-get-config", "@ember/runloop", "@ember/debug", "ember", "@sentry/utils"], function (_exports, Sentry, _emberGetConfig, _runloop, _debug, _ember, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {
    InitSentryForEmber: true,
    getActiveTransaction: true,
    instrumentRoutePerformance: true
  };
  _exports.InitSentryForEmber = InitSentryForEmber;
  _exports.instrumentRoutePerformance = _exports.getActiveTransaction = void 0;
  Object.keys(Sentry).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === Sentry[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return Sentry[key];
      }
    });
  });

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function InitSentryForEmber(_runtimeConfig) {
    const config = _emberGetConfig.default['@sentry/ember'];
    (false && !(config) && (0, _debug.assert)('Missing configuration', config));
    (false && !(config.sentry) && (0, _debug.assert)('Missing configuration for Sentry.', config.sentry));
    const initConfig = Object.assign({}, config.sentry, _runtimeConfig || {});
    createEmberEventProcessor();
    Sentry.init(initConfig);
  }

  const getActiveTransaction = () => {
    var _Sentry$getCurrentHub, _Sentry$getCurrentHub2;

    return (_Sentry$getCurrentHub = Sentry.getCurrentHub()) === null || _Sentry$getCurrentHub === void 0 ? void 0 : (_Sentry$getCurrentHub2 = _Sentry$getCurrentHub.getScope()) === null || _Sentry$getCurrentHub2 === void 0 ? void 0 : _Sentry$getCurrentHub2.getTransaction();
  };

  _exports.getActiveTransaction = getActiveTransaction;

  const instrumentRoutePerformance = BaseRoute => {
    const instrumentFunction = async (op, description, fn, args) => {
      const startTimestamp = (0, _utils.timestampWithMs)();
      const result = await fn(...args);
      const currentTransaction = getActiveTransaction();

      if (!currentTransaction) {
        return result;
      }

      currentTransaction.startChild({
        op,
        description,
        startTimestamp
      }).finish();
      return result;
    };

    return {
      [BaseRoute.name]: class extends BaseRoute {
        beforeModel(...args) {
          return instrumentFunction('ember.route.beforeModel', this.fullRouteName, super.beforeModel, args);
        }

        async model(...args) {
          return instrumentFunction('ember.route.model', this.fullRouteName, super.model, args);
        }

        async afterModel(...args) {
          return instrumentFunction('ember.route.afterModel', this.fullRouteName, super.afterModel, args);
        }

        async setupController(...args) {
          return instrumentFunction('ember.route.setupController', this.fullRouteName, super.setupController, args);
        }

      }
    }[BaseRoute.name];
  };

  _exports.instrumentRoutePerformance = instrumentRoutePerformance;

  function createEmberEventProcessor() {
    if (Sentry.addGlobalEventProcessor) {
      (0, Sentry.addGlobalEventProcessor)(event => {
        event.sdk = _objectSpread(_objectSpread({}, event.sdk), {}, {
          name: 'sentry.javascript.ember',
          packages: [...(event.sdk && event.sdk.packages || []), {
            name: 'npm:@sentry/ember',
            version: Sentry.SDK_VERSION
          }],
          version: Sentry.SDK_VERSION
        });
        return event;
      });
    }
  }
});