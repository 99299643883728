define('ember-can/services/can', ['exports', 'ember-can/ability', 'ember-can/utils/normalize'], function (exports, _ability, _normalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * Parse ablityString into an object with extracted propertyName and abilityName
     * eg. for 'create projects in account' -> `{ propertyName: 'createProjects', abilityName: 'account'}`
     * @public
     * @param  {String} string eg. 'create projects in account'
     * @return {Object}        extracted propertyName and abilityName
     */
    parse(abilityString) {
      return (0, _normalize.default)(abilityString);
    },

    /**
     * Create an instance of Ability
     * @public
     * @param  {String} abilityName     name of ability class
     * @param  {*}      model
     * @param  {Object} [properties={}] extra properties (to be set on the ability instance)
     * @return {Ability}                Ability instance of requested ability
     */
    abilityFor(abilityName, model, properties = {}) {
      let AbilityFactory = Ember.getOwner(this).factoryFor(`ability:${abilityName}`);

      (false && !(AbilityFactory) && Ember.assert(`No ability type found for '${abilityName}'`, AbilityFactory));


      if (typeof model !== 'undefined') {
        properties = Ember.assign({}, { model }, properties);
      }

      let ability = AbilityFactory.create(properties);
      (false && !(ability instanceof _ability.default) && Ember.assert(`Ability '${abilityName}' has to inherit from ember-can Ability`, ability instanceof _ability.default));


      return ability;
    },

    /**
     * Returns a value for requested ability in specified ability class
     * @public
     * @param  {String} propertyName name of ability, eg `createProjects`
     * @param  {String} abilityName  name of ability class
     * @param  {*}      model
     * @param  {Object} properties   extra properties (to be set on the ability instance)
     * @return {*}                   value of ability
     */
    valueFor(propertyName, abilityName, model, properties) {
      let ability = this.abilityFor(abilityName, model, properties);
      let result = ability.getAbility(propertyName);

      ability.destroy();

      return result;
    },

    /**
     * Returns `true` if ability is permitted
     * @public
     * @param  {[type]} abilityString eg. 'create projects in account'
     * @param  {*}      model
     * @param  {[type]} properties    extra properties (to be set on the ability instance)
     * @return {Boolean}              value of ability converted to boolean
     */
    can(abilityString, model, properties) {
      var _parse = this.parse(abilityString);

      let propertyName = _parse.propertyName,
          abilityName = _parse.abilityName;

      return !!this.valueFor(propertyName, abilityName, model, properties);
    },

    /**
     * Returns `true` if ability is not permitted
     * @public
     * @param  {[type]} abilityString eg. 'create projects in account'
     * @param  {*}      model
     * @param  {[type]} properties    extra properties (to be set on the ability instance)
     * @return {Boolean}              value of ability converted to boolean
     */
    cannot(abilityString, model, properties) {
      return !this.can(abilityString, model, properties);
    }
  });
});