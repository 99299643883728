define("ember-stargate/components/portal-target", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@ember/debug"], function (_exports, _component, _templateFactory, _component2, _object, _service, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{did-insert this.register}}
    {{will-destroy this.unregister}}
    ...attributes
  >
    {{yield this.count}}
  </div>
  */
  {
    "id": "DU6aYr6I",
    "block": "[[[11,0],[17,1],[4,[38,0],[[30,0,[\"register\"]]],null],[4,[38,1],[[30,0,[\"unregister\"]]],null],[12],[1,\"\\n  \"],[18,2,[[30,0,[\"count\"]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-stargate/components/portal-target.hbs",
    "isStrictMode": false
  });

  let PortalTargetComponent = (_dec = (0, _service.inject)('-portal'), (_class = class PortalTargetComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "portalService", _descriptor, this);
    }

    get count() {
      return this.portalService.getPortalCount(this.args.name);
    }

    register(element) {
      (false && !(this.args.name) && (0, _debug.assert)('PortalTargets needs a name', this.args.name));
      const options = {
        multiple: this.args.multiple,
        onChange: this.args.onChange
      };
      this.portalService.registerTarget(this.args.name, element, options);
    }

    unregister() {
      this.portalService.unregisterTarget(this.args.name);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "portalService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "register", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unregister", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "unregister"), _class.prototype)), _class));
  _exports.default = PortalTargetComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PortalTargetComponent);
});