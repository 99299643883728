define("ember-key-manager/services/key-manager", ["exports", "@ember/service", "ember-key-manager/utils/macro", "ember-key-manager/utils/modifier-keys", "@ember/debug", "@ember/utils", "ember-key-manager/utils/warning-messages", "@ember/array", "@ember/string"], function (_exports, _service, _macro, _modifierKeys, _debug, _utils, _warningMessages, _array, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const inputElements = ['INPUT', 'SELECT', 'TEXTAREA'];

  const isInputElement = element => {
    const isContentEditable = element.isContentEditable;
    const isInput = inputElements.includes(element.tagName);
    return isContentEditable || isInput;
  };

  let KeyManagerService = (_class = class KeyManagerService extends _service.default {
    // Config option
    get keydownMacros() {
      return this.macros.filterBy('keyEvent', 'keydown');
    }

    get keyupMacros() {
      return this.macros.filterBy('keyEvent', 'keyup');
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "config", _descriptor, this);

      _defineProperty(this, "isDisabledOnInput", false);

      _defineProperty(this, "macros", (0, _array.A)());

      this._registerConfigOptions();
    }

    addMacro(options) {
      options = this._mergeConfigDefaults(options);
      const macro = new _macro.default(options);
      const keyEvent = macro.keyEvent;

      this._handleModifiersOnKeyup(macro, keyEvent);

      const element = macro.element;

      this._addEventListener(element, keyEvent);

      const macros = this.macros;
      macros.pushObject(macro);
      return macro;
    }

    removeMacro(macro) {
      this.macros.removeObject(macro);

      this._removeEventListenter(macro.element, macro.keyEvent);
    }

    _handleModifiersOnKeyup({
      modifierKeys
    }, keyEvent) {
      if (keyEvent === 'keyup' && (0, _utils.isPresent)(modifierKeys)) {
        (false && (0, _debug.warn)(_warningMessages.MODIFIERS_ON_KEYUP, false, {
          id: 'keyup-with-modifiers'
        }));
      }
    }

    _mergeConfigDefaults(options) {
      if (options.isDisabledOnInput == undefined) {
        options.isDisabledOnInput = this.isDisabledOnInput;
      }

      return options;
    }

    _addEventListener(element, keyEvent) {
      const hasListenerForElementAndKeyEvent = this._findMacroWithElementAndKeyEvent(element, keyEvent);

      if (!hasListenerForElementAndKeyEvent) {
        element.addEventListener(keyEvent, this);
      }
    }

    _removeEventListenter(element, keyEvent) {
      const hasListenerForElementAndKeyEvent = this._findMacroWithElementAndKeyEvent(element, keyEvent);

      if (!hasListenerForElementAndKeyEvent) {
        element.removeEventListener(keyEvent, this);
      }
    }

    disable(recipient) {
      this._setDisabledState(recipient, true);
    }

    enable(recipient) {
      this._setDisabledState(recipient, false);
    }

    handleEvent(event) {
      // called by element event listener
      if (this.isDestroyed || this.isDestroying) {
        return false;
      }

      const isKeydown = event.type === 'keydown';
      const isKeyup = event.type === 'keyup';

      if (isKeydown || isKeyup) {
        const allEventModifierKeys = {
          altKey: event.altKey,
          ctrlKey: event.ctrlKey,
          metaKey: event.metaKey,
          shiftKey: event.shiftKey
        };
        const eventModifierKeys = (0, _array.A)(Object.keys(allEventModifierKeys).filter(key => {
          return allEventModifierKeys[key] !== false;
        }));
        const targetIsElement = event.target instanceof HTMLElement;
        const matchingMacros = targetIsElement ? this._findMatchingMacros(event.target, event.key || '', eventModifierKeys, event.type) : [];

        if ((0, _utils.isPresent)(matchingMacros)) {
          const isTargetInput = targetIsElement && isInputElement(event.target);
          event.stopPropagation();
          matchingMacros.forEach(matchingMacro => {
            const isDisabled = matchingMacro.isDisabled || matchingMacro.isDisabledOnInput && isTargetInput;

            if (!isDisabled) {
              matchingMacro.callback(event);
            }
          });
        }
      }

      return false;
    }

    _findMacroWithElementAndKeyEvent(eventElement, eventKeyEvent) {
      var events = eventKeyEvent === "keydown" ? this.keydownMacros : this.keyupMacros;
      return events.find(macro => {
        const element = macro.element;
        return eventElement === element;
      });
    }

    _findMatchingMacros(eventElement, eventExecutionKey, eventModifierKeys, eventKeyEvent) {
      var events = eventKeyEvent === "keydown" ? this.keydownMacros : this.keyupMacros;
      const matchingMacros = events.filter(macro => {
        const element = macro.element;
        const executionKey = macro.executionKey;
        const modifierKeys = macro.modifierKeys;
        const hasElementMatch = element === eventElement || element.contains(eventElement);
        const hasExecutionKeyMatch = eventExecutionKey.toLowerCase() === executionKey.toLowerCase();
        const onlyModifierKeys = (0, _array.A)(eventModifierKeys);
        onlyModifierKeys.removeObject(_modifierKeys.TO_MODIFIER[eventExecutionKey]);
        const hasModifierKeysMatch = onlyModifierKeys.every(key => {
          return modifierKeys.map(k => (0, _string.capitalize)(k)).includes(_modifierKeys.TO_KEY[key]);
        });
        const hasModifierKeyCount = eventModifierKeys.length === modifierKeys.length;
        return hasElementMatch && hasExecutionKeyMatch && hasModifierKeysMatch && hasModifierKeyCount;
      });
      const highestPriority = (0, _array.A)(matchingMacros).mapBy('priority').reduce((max, priority) => Math.max(max, priority), -Infinity);
      return matchingMacros.filter(macro => macro.priority === highestPriority);
    }

    _registerConfigOptions() {
      if (this.config.isDisabledOnInput !== undefined) {
        this.isDisabledOnInput = this.config.isDisabledOnInput;
      }
    }

    _setDisabledState(recipient, isDisabled) {
      if (typeof recipient === 'string') {
        this._setGroupDisabledState(recipient, isDisabled);
      } else {
        recipient.isDisabled = isDisabled;
      }
    }

    _setGroupDisabledState(groupName, isDisabled) {
      this.macros.filterBy('groupName', groupName).setEach('isDisabled', isDisabled);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = KeyManagerService;
});