define('ember-can/ability', ['exports', '@ember/string'], function (exports, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    model: null,

    /**
     * Parse propertyName into ability property
     * eg: `createProject` will be parsed to `canCreateProject` using default definition
     * @public
     * @param  {[String]} propertyName [description]
     * @return {[String]}              [description]
     */
    parseProperty(propertyName) {
      return (0, _string.camelize)(`can-${propertyName}`);
    },

    /**
     * Get parsed ability value based on propertyName
     * eg: `createProject` will return a value for `canCreateProject`
     * using default `parseProperty` definition
     * @private
     * @param  {String} propertyName property name, eg. `createProject`
     * @return {*}                   value of parsed `propertyName` property
     */
    getAbility(propertyName) {
      return this.get(this.parseProperty(propertyName));
    }
  });
});