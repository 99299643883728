define("@ember/legacy-built-in-components/index", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LinkComponent = _exports.TextArea = _exports.TextField = _exports.Checkbox = void 0;

  /* eslint-disable ember/new-module-imports */
  const Checkbox = _ember.default._LegacyCheckbox.extend();

  _exports.Checkbox = Checkbox;

  const TextField = _ember.default._LegacyTextField.extend();

  _exports.TextField = TextField;

  const TextArea = _ember.default._LegacyTextArea.extend();

  _exports.TextArea = TextArea;

  const LinkComponent = _ember.default._LegacyLinkComponent.extend();

  _exports.LinkComponent = LinkComponent;
});